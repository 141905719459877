/**
 * Ionic 4 Spotify PWA and App Starter  (https://store.enappd.com/product/iionic-4-spotify-pwa-and-app-starter)
 *
 * Copyright © 2019-present Enappd. All rights reserved.
 *
 * This source code is licensed as per the terms found in the
 * LICENSE.md file in the root directory of this source tree.
 */
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FormsModule } from '@angular/forms';
import { SearchartistComponent } from './searchartist/searchartist.component';
import { MusicControls } from '@ionic-native/music-controls/ngx';
import { Media, MediaObject } from '@ionic-native/media/ngx';
import { File } from '@ionic-native/file/ngx';
import { SongsactionmodalComponent } from './songsactionmodal/songsactionmodal.component';
import { ShowcreditsComponent } from './showcredits/showcredits.component';
import { PlaylistmodalComponent } from './playlistmodal/playlistmodal.component';
import { AddsongsmodalComponent } from './addsongsmodal/addsongsmodal.component';
import { AddsongslistmodalComponent } from './addsongslistmodal/addsongslistmodal.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { LangMessages } from '../languages/messages';

// auth mod 20191612
import { HttpClientModule } from '@angular/common/http';
import { NativeStorage } from '@ionic-native/native-storage/ngx';

@NgModule({
  declarations: [AppComponent,
    SearchartistComponent,
    SongsactionmodalComponent,
    PlaylistmodalComponent,
    ShowcreditsComponent,
    AddsongsmodalComponent,
    AddsongslistmodalComponent
  ],
  entryComponents: [
    SearchartistComponent,
    SongsactionmodalComponent,
    ShowcreditsComponent,
    PlaylistmodalComponent,
    AddsongsmodalComponent,
    AddsongslistmodalComponent
  ],
  imports: [
    BrowserModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    FormsModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
    HttpClientModule
  ],
  providers: [
    StatusBar,
    SplashScreen,
    MusicControls,
    Media,
    File,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    NativeStorage,
    LangMessages
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
