/**
 * Ionic 4 Spotify PWA and App Starter  (https://store.enappd.com/product/iionic-4-spotify-pwa-and-app-starter)
 *
 * Copyright © 2019-present Enappd. All rights reserved.
 *
 * This source code is licensed as per the terms found in the
 * LICENSE.md file in the root directory of this source tree.
 */
import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { SpotifyService } from '../spotify.service';

@Component({
  selector: 'app-searchartist',
  templateUrl: './searchartist.component.html',
  styleUrls: ['./searchartist.component.scss'],
})
export class SearchartistComponent implements OnInit {
  searchInput = '';
  findArtist = 'Find your favorite artists';
  trySearching = 'Try searching or using a different spelling or keyword';
  searchData = [
    { image: '../../assets/chat1.jpg', name: 'Drama', show: true, id: 0 },
    { image: '../../assets/chat2.jpg', name: 'Songs', show: true, id: 1 },
    { image: '../../assets/chat4.jpg', name: 'Bigz', show: true, id: 2 },
    { image: '../../assets/chat5.jpg', name: 'Mp3', show: true, id: 3 },
    { image: '../../assets/chat6.jpg', name: 'Solid', show: true, id: 4 },
    { image: '../../assets/chat7.jpg', name: 'English', show: true, id: 5 },
    { image: '../../assets/chat8.jpg', name: 'Marathi', show: true, id: 6 },
    { image: '../../assets/chat3.jpg', name: 'Gujrati', show: true, id: 7 },
    { image: '../../assets/chat5.jpg', name: 'Bengali', show: true, id: 8 },
    { image: '../../assets/chat6.jpg', name: 'Kannada', show: true, id: 9 },
    { image: '../../assets/chat1.jpg', name: 'Hindi', show: true, id: 0 },
  ];
  constructor(
    public modalCtrl: ModalController,
    public serviceProvider: SpotifyService
  ) { }

  ngOnInit() { }
  dissmiss() {
    this.modalCtrl.dismiss();
  }
  artistSelected(newData: { image: string; name: string; show: boolean; id: number; }) {
    console.log(this.searchInput);
    this.serviceProvider.artistData.push(newData);
    this.serviceProvider.buttonToggle = true;
    this.dissmiss();
  }
}
