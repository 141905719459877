/**
 * Ionic 4 Spotify PWA and App Starter  (https://store.enappd.com/product/iionic-4-spotify-pwa-and-app-starter)
 *
 * Copyright © 2019-present Enappd. All rights reserved.
 *
 * This source code is licensed as per the terms found in the
 * LICENSE.md file in the root directory of this source tree.
 */
import { Component } from '@angular/core';

// mzupdt import { Platform } from '@ionic/angular';
import { Platform, NavController } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
// mzupdt
import { AuthService } from './services/auth.service';
import { AlertService } from './services/alert.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html'
})
export class AppComponent {

  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    // mzupdt
    private authService: AuthService,
    private navCtrl: NavController,
    private alertService: AlertService
    // end of mzupdt
  ) {
    this.initializeApp();
  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.statusBar.styleLightContent(); // mzupdt comment this too ?
      // mzupdt this.splashScreen.hide();
      this.authService.getToken();
    });
  }
}
