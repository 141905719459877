import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
  })
  export class LangMessages {
    public language = 'ptbr';
    // Tab1 Messages
    public MsgErrorretrieve = {
        ptbr: 'Erro ao buscar seu conteúdo. Verifique sua conexão.',
        us: 'Error retrieving your content. Check your connection.',
        es: 'Error al recuperar tu contenido. Comprueba tu conexión.'
    };
    public MsgPulling = {
        ptbr: 'Puxe para atualizar.',
        us: 'Pull to refresh.',
        es: 'Tire para actualizar.'
    };
    public MsgRefreshing = {
        ptbr: 'Atualizando...',
        us: 'Refreshing...',
        es: 'Actualizando...'
    };
    public MsgLoadingmore = {
        ptbr: 'Carregando mais dados...',
        us: 'Loading more data...',
        es: 'Cargando más datos...'
    };
    public MsgLoginSucessful = {
        ptbr: 'Olá. Bem vindo !',
        us: 'Hi. Welcome !',
        es: 'Hola. Bienvenido !'
    };
    public MsgLoginFail = {
        ptbr: 'Falha na autenticação.',
        us: 'Login failed.',
        es: 'Autenticación fallida.'
    };
    public MsgCreateAccount = {
        ptbr: 'Criar uma conta.',
        us: 'Create account.',
        es: 'Create account.'
    };
    public MsgCreatePwd = {
        ptbr: 'Crie uma senha.',
        us: 'Create a password.',
        es: 'Create a password.'
    };
    public MsgEmailInput = {
        ptbr: 'Qual seu e-mail?',
        us: 'What\'s your email?',
        es: 'What\'s your email?'
    };
    public MsgEmailInvalid = {
        ptbr: 'E-mail inválido.',
        us: 'Wrong e-mail.',
        es: 'Wrong e-mail.'
    };
    public MsgEmailNotFound = {
        ptbr: 'Não encontramos um usuário com esse e-mail.',
        us: 'We can\'t find a user with that e-mail address.',
        es: 'We can\'t find a user with that e-mail address.'
    };
    public MsgEmailConf = {
        ptbr: 'Você precisará confirmar esse e-mail em seguida.',
        us: 'You\'ll need to confirm this email later.',
        es: 'You\'ll need to confirm this email later.'
    };
    public MsgPwdChr = {
        ptbr: 'Use ao menos oito caracteres.',
        us: 'Use at least eight characters.',
        es: 'Use at least eight characters.'
    };
    public MsgLogin = {
        ptbr: 'Login',
        us: 'Login',
        es: 'Login'
    };
    public MsgNext = {
        ptbr: 'Avançar',
        us: 'Next',
        es: 'Next'
    };
    public MsgPrior = {
        ptbr: 'Voltar',
        us: 'Prior',
        es: 'Prior'
    };
    public MsgEmailName = {
        ptbr: 'E-mail',
        us: 'E-mail',
        es: 'E-mail'
    };
    public MsgPwd = {
        ptbr: 'Senha',
        us: 'Password',
        es: 'Password'
    };
    public MsgPasswordResetSent = {
        ptbr: 'Enviamos um link de redefinição de senha por e-mail!',
        us: 'We have e-mailed your password reset link!',
        es: 'We have e-mailed your password reset link!'
    };
    public MsgLoginHelp = {
        ptbr: 'Problemas ? Ajuda por aqui.',
        us: 'Having trouble logging in? Get help here.',
        es: 'Having trouble logging in? Get help here.'
    };
    public MsgConfirm = {
        ptbr: 'Conta não confirmada',
        us: 'Unverified Account',
        es: 'Unverified Account'
    };
    public MsgConfirmTip = {
        ptbr: 'Clique para re-enviar o e-mail de confirmação.',
        us: 'Click to resend the confirmation email.',
        es: 'Click to resend the confirmation email.'
    };
    public MsgConfirmTitle = {
        ptbr: 'Sua conta ainda não foi confirmada. Verifique as instruções enviadas por e-mail na sua caixa de entrada.',
        us: 'Your account has not been verified yet. Check the instructions sent by email in your inbox.',
        es: 'Your account has not been verified yet. Check the instructions sent by email in your inbox.'
    };
    public MsgRecover = {
        ptbr: 'Recuperar sua conta',
        us: 'Recover Your Account',
        es: 'Recover Your Account'
    };
    public MsgRecoverTitle = {
        ptbr: 'Digite seu e-mail utilizado ao se registrar. Vamos te enviar um link para recuperar sua conta.',
        us: 'Enter your email address when registering. We\'ll send you a link to recover your account.',
        es: 'Enter your email address when registering. We\'ll send you a link to recover your account.'
    };
    public MsgRecoverHelp = {
        ptbr: 'Ainda precisa de ajuda ? Clique aqui!',
        us: 'Still need help? Click here!',
        es: 'Still need help? Click here!'
    };
    public MsgSend = {
        ptbr: 'Enviar',
        us: 'Send',
        es: 'Send'
    };
    public MsgReSend = {
        ptbr: 'Re-Enviar',
        us: 'Re-Send',
        es: 'Re-Send'
    };
    public MsgNickNameInput = {
        ptbr: 'Qual seu apelido?',
        us: 'What\'s your nick name?',
        es: 'What\'s your nick name?'
    };
    public MsgNickName = {
        ptbr: 'Por exemplo: thedoctor',
        us: 'For example: thedoctor',
        es: 'For example: thedoctor'
    };
    public MsgFirstNameInput = {
        ptbr: 'Qual seu primeiro nome?',
        us: 'What\'s your first name?',
        es: 'What\'s your first name?'
    };
    public MsgFirstName = {
        ptbr: 'Por exemplo: John',
        us: 'For example: John',
        es: 'For example: John'
    };
    public MsgLastNameInput = {
        ptbr: 'Qual seu sobrenome?',
        us: 'What\'s your last name?',
        es: 'What\'s your last name?'
    };
    public MsgLastName = {
        ptbr: 'Por exemplo: Doe',
        us: 'For example: Doe',
        es: 'For example: Doe'
    };
    public MsgSignUp = {
        ptbr: 'Criar uma nova conta',
        us: 'Create new account',
        es: 'Create new account'
    };
    public MsgSignupError = {
        ptbr: 'Verifique os erros abaixo.',
        us: 'Check the errors below.',
        es: 'Check the errors below.'
    };
    public MsgSignupSuccess = {
        ptbr: 'Quase lá ! Confirme o seu login através do link enviado para o seu e-mail.',
        us: 'Almost there ! Confirm your login via the link sent to your email.',
        es: 'Almost there ! Confirm your login via the link sent to your email.'
    };
    public MsgResentConfirm = {
        ptbr: 'Pronto. Confirme o seu login através do link re-enviado para o seu e-mail.',
        us: 'Ready ! Confirm your login via the link re-sent to your email.',
        es: 'Ready ! Confirm your login via the link re-sent to your email.'
    };
    public MsgAlreadyConfirmed = {
        ptbr: 'Ops. Essa conta já está ativa. Faça seu login diretamente.',
        us: 'Oops This account is already active. Log in directly.',
        es: 'Oops This account is already active. Log in directly.'
    };
    // tab
    public MsgTabHead = {
        ptbr: 'Ei',
        us: 'Hey',
        es: 'Oye'
    };
    public MsgTabTitle = {
        ptbr: 'Aqui estão alguns conteúdos baseados no seu gosto profissional.',
        us: 'Here are some playlist based on your professional taste.',
        es: 'Aquí hay una lista de reproducción basada en su gusto profesional.'
    };
}
