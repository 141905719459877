/**
 * Ionic 4 Spotify PWA and App Starter  (https://store.enappd.com/product/iionic-4-spotify-pwa-and-app-starter)
 *
 * Copyright © 2019-present Enappd. All rights reserved.
 *
 * This source code is licensed as per the terms found in the
 * LICENSE.md file in the root directory of this source tree.
 */
import { Component, OnInit, Input } from '@angular/core';
import { NavParams, ModalController } from '@ionic/angular';
import { SpotifyService } from '../spotify.service';
import { Router } from '@angular/router';
import { ShowcreditsComponent } from '../showcredits/showcredits.component';

@Component({
  selector: 'app-songsactionmodal',
  templateUrl: './songsactionmodal.component.html',
  styleUrls: ['./songsactionmodal.component.scss'],
})
export class SongsactionmodalComponent implements OnInit {
  @Input() value: any;
  modalData: any;
  clickedHeart: any;
  clickedMinus: any;
  listActions: { icon: string; textTitle: string; }[];
  album = '../../assets/album.jfif';
  // tslint:disable-next-line: variable-name
  heart_value: any;
  // tslint:disable-next-line: variable-name
  minus_value: any;
  data: any;
  constructor(
    public navParam: NavParams,
    public serviceProvider: SpotifyService,
    public modalCtrl: ModalController,
    public route: Router
  ) {
    this.modalData = this.navParam.get('value');
    console.log(this.modalData);
    this.listActions = this.serviceProvider.songActions;

    this.serviceProvider.valueHeart.subscribe(filteredHeart => {
      this.clickedHeart = filteredHeart;
      console.log('clickedHeart', this.clickedHeart);
    });

    this.serviceProvider.valueMinus.subscribe(filter => {
      this.clickedMinus = filter;
      console.log('filter', this.clickedMinus);
    });

  }

  ngOnInit() { }

  closeModal() {
    this.modalCtrl.dismiss();
  }

  changeHeartToggleValue() {
    if (this.clickedHeart) {
      this.clickedHeart = false;
      this.serviceProvider.toggleHeart(this.clickedHeart);
      this.serviceProvider.togHeart = true;
    } else if (!this.clickedHeart) {
      this.clickedHeart = true;
      this.serviceProvider.toggleHeart(this.clickedHeart);
      this.serviceProvider.togHeart = true;

    }
    this.modalCtrl.dismiss();
    console.log('value', this.heart_value);
  }

  changeMinusToggleValue() {
    if (this.clickedMinus) {
      this.clickedMinus = false;
      this.serviceProvider.toggleMinus(this.clickedMinus);
      this.serviceProvider.togMinus = true;
    } else if (!this.clickedMinus) {
      this.clickedMinus = true;
      this.serviceProvider.toggleMinus(this.clickedMinus);
      this.serviceProvider.togMinus = true;
    }
    this.modalCtrl.dismiss();
    console.log('value clickedMinus', this.clickedMinus);
  }
  async  goForActions(list) {
    console.log(list);
    switch (list.type) {
      case 'playlist':
        this.data = {
          artist: this.modalData.artist,
          title: this.modalData.title,
          url: this.modalData.url,
          comingFrom: 'pageAnother',
          clickedHeart: this.modalData.clickedHeart,
          clickedMinus: this.modalData.clickedMinus
        };
        this.route.navigate(['tabs/library', this.data]);
        this.closeModal();
        break;
      case 'add':
        this.route.navigate(['addplaylist']);
        this.closeModal();
        break;
      case 'Artist':
        this.route.navigate(['addplaylist']);
        this.closeModal();
        break;
      case 'Share':
        this.route.navigate(['addplaylist']);
        this.closeModal();
        break;
      case 'Report':
        this.route.navigate(['addplaylist']);
        this.closeModal();
        break;
      case 'Credits':
        // const modal = await this.serviceProvider.loadModal(ShowcreditsComponent);
        // modal.present();
        this.closeModal();
        break;
    }
  }
}
