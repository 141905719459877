/**
 * Ionic 4 Spotify PWA and App Starter  (https://store.enappd.com/product/iionic-4-spotify-pwa-and-app-starter)
 *
 * Copyright © 2019-present Enappd. All rights reserved.
 *
 * This source code is licensed as per the terms found in the
 * LICENSE.md file in the root directory of this source tree.
 */
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-showcredits',
  templateUrl: './showcredits.component.html',
  styleUrls: ['./showcredits.component.scss'],
})
export class ShowcreditsComponent implements OnInit {

  constructor() { }

  ngOnInit() {}

}
