import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { tap, catchError } from 'rxjs/operators';
import { NativeStorage } from '@ionic-native/native-storage/ngx';
import { EnvService } from './env.service';
import { User } from '../models/user';
import { Settings } from 'src/app/models/settings';
import { throwError, Observable, of } from 'rxjs';
import { BehaviorSubject } from 'rxjs';

import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})

export class AuthService {
  isLoggedIn = false;
  token: any;
  homeData: any = [];
  public userEmail = '';

  public userdataSource = new BehaviorSubject<User[]>([]); // pode ser private ??
  // dataOfUser = this.userdataSource.asObservable(); // apenas listen, not emmit

  public userSettings = new BehaviorSubject<Settings[]>([]); // pode ser private ??

  constructor(
    private http: HttpClient,
    private storage: NativeStorage,
    private env: EnvService,
  ) { }


  getUser(): Observable<User[]> {
    if (this.userdataSource.getValue().length === 0) {
      this.loadUserData();
    }
    return this.userdataSource.asObservable();
  }

  getSettings(): Observable<Settings[]> {
    if (this.userSettings.getValue().length === 0) {
      this.loadUserSettings();
    }
    return this.userSettings.asObservable();
  }

  gethomeData(page: number, lista = false, id = 0) {
    return new Promise((resolve, reject) => {
      let url;

      url = lista ? environment.apiUrl + '/content/?per_page=8&page=' + page :
                    environment.apiUrl + '/content' + id;

      const headers = new HttpHeaders({
        Authorization: this.token.token_type + ' ' + this.token.access_token
      });
      this.http.get(url, { headers })
        .subscribe((result: any) => {
          resolve(result);
        },
          (error) => {
            if (environment.dbgLog) { console.log ('gethomeData: reject error'); }
            // reject(error.json());
            reject(error);
          });
    });
  }

  // future move to api.ts
  private loadUserData(): void {
    const headers = new HttpHeaders({
      Authorization: this.token.token_type + ' ' + this.token.access_token
    });

    this.http.get<User[]>(this.env.API_URL + 'auth/user', { headers }).subscribe(
      (data: User[]) => {
        this.userdataSource.next(data);
      }
    );
  }

  private loadUserSettings(): void {
    const headers = new HttpHeaders({
      Authorization: this.token.token_type + ' ' + this.token.access_token
    });

    /* enable after api ready
    this.http.get<Settings[]>(this.env.API_URL + 'auth/settings', { headers }).subscribe(
      (data: Settings []) => {
        this.userSettings.next(data);
      }
    );
    */
    const settingsData = [
    {
      head: 'Data Saver',
      content: [{ action: 'On', description: 'Sets your music quality to low and disables Canvas.', toggle: true }]
    },
    {
      head: 'Playback',
      content:
        [{ action: 'Crossfade', description: 'Allows you to crossfade between songs', toggle: false, range: true },
        { action: 'Gapless', description: 'Allows gapless playback', toggle: true },
        { action: 'Allow Explicit Content', description: 'Turn onto play explicit content.explicit contentis labeled with E tag', toggle: true },
        { action: 'Show unplayable songs', description: 'show songs that are unplayable', toggle: true },
        { action: 'Normalize volume', description: 'Set the same volume level for all tracks', toggle: true },
        { action: 'Device broadcsat Status', description: 'Allow other app on your device to see what you are listening to.', toggle: true },
        { action: 'Autoplay', description: 'Keepos on listening to similar tracks when music ends.', toggle: true },
        { action: 'Canvas', description: 'Play canvases for the current track. They are optimized to use very little data and battery/.', toggle: true }
        ]
    },
    {
      head: 'Languages',
      content: [{ action: 'Languages for music', type: 'Languages', description: 'Choose your preferred language for music', toggle: false }]
    },
    {
      head: 'Devices',
      content: [{ action: 'Connect to adevice', description: 'Listen to and control Spotify on your device', toggle: false },
      { action: 'Show local devices only', description: 'Only show devices on your local wifi in your device menu', toggle: true }]
    },
    {
      head: 'Car View',
      content: [{ action: 'Turn on automatically', description: 'Spotify will switch to car view whenever Bluetooth is detected in your car', toggle: true }]
    },
    {
      head: 'Social',
      content: [{ action: 'Private session', description: 'Start aprivate session to listen anonymously', toggle: true },
      { action: 'Listening Activity', description: 'Share what i listen to with my followers on Spotify.', toggle: true },
      { action: 'Connect to Facebook', type: 'Facebook', description: '', toggle: false }]
    },

    {
      head: '',
      content: [{ action: 'Waze navigation', description: 'enable the waze integration', toggle: true },
      { action: 'Download using cellular', description: 'Recommended setting: Off', toggle: true },
      { action: 'Delete Cache', description: 'You can free up storage by deketing your cache. Your download\'s wont\'t be deleted.', toggle: false }]
    },
    {
      head: 'Notifications',
      content: [{ action: 'Notifications', type: 'Notifications', description: 'choose which notifications to receive', toggle: false }]
    },
    {
      head: 'Advertisements',
      content: [{ action: 'Spotify Ad Partner Preferences', description: 'Control how ads are targeted to me based on information gathered from advertising partners.', toggle: true }]
    },
    {
      head: 'About',
      content: [{ action: 'Version', description: '1.0.0.1', toggle: false },
      { action: 'Third-party software', description: 'Sweet software that helped us', toggle: false },
      { action: 'Terms and Conditions', description: 'All the stuff you need to know', toggle: false },
      { action: 'Privacy Policy', description: 'Important for both of us', toggle: false },
      { action: 'Support', description: 'Get help from us and the communoty', toggle: false },
      { action: 'Log out', type: 'Logout', description: 'You are logged in', toggle: false },
      ]
    },
    {
      head: 'Other',
      content: [
        { action: 'Storage', description: 'Choose where to store your music data', toggle: false },
        { action: 'Log out', type: 'Logout', description: 'You are logged in', toggle: false },
      ]
    },
    ];
    this.userSettings.next(settingsData);
  }

  login(email: string, password: string) {
    this.userEmail = email;
    // todo: this generates 401 error on failed auth, because no error / try implemented
    return this.http.post(this.env.API_URL + 'auth/login',
      {email, password}
    ).pipe(
      tap(token => {
        this.storage.setItem('token', token)
        .then(
          () => {
            console.log('Token Stored');
          },
          error => console.error('Error storing item', error)
        );
        this.token = token;
        this.isLoggedIn = true;
        this.getUser();
        return token;
      }),
    );
  }

  register(nName: string, fName: string, lName: string, email: string, password: string) {
    const confpassword = password; // force confirmation
    this.userEmail = email;
    return this.http.post(this.env.API_URL + 'auth/signup',
      {name: nName, first_name: fName, last_name: lName, email, password, password_confirmation: confpassword}
    );
  }

  resetPassword(email: string) {
    return this.http.post(this.env.API_URL + 'password/create',
      {email}
    );
  }

  resendConfirmation(email: string) {
    return this.http.get(this.env.API_URL + 'auth/signup/resend/' + email);
  }

  logout() {
    const headers = new HttpHeaders({
      Authorization: this.token.token_type + ' ' + this.token.access_token
    });

    return this.http.get(this.env.API_URL + 'auth/logout', { headers })
    .pipe(
      tap(data => {
        // console.log('called api: logged out');
        this.storage.remove('token');
        this.isLoggedIn = false;
        delete this.token;
        this.userEmail = '';
        this.userdataSource.next([]);
        this.userSettings.next([]);
        return data;
      }),
      catchError(err => {
        console.log('error: logout failed: ', err);
        return throwError(err);
    })
    );
  }

  getToken() {
    return this.storage.getItem('token').then(
      data => {
        this.token = data;

        if (this.token != null) {
          this.isLoggedIn = true;
        } else {
          this.isLoggedIn = false;
        }
      },
      error => {
        this.token = null;
        this.isLoggedIn = false;
      }
    );
  }
}
