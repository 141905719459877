/**
 * Ionic 4 Spotify PWA and App Starter  (https://store.enappd.com/product/iionic-4-spotify-pwa-and-app-starter)
 *
 * Copyright © 2019-present Enappd. All rights reserved.
 *
 * This source code is licensed as per the terms found in the
 * LICENSE.md file in the root directory of this source tree.
 */
import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './guard/auth.guard';

const routes: Routes = [
  // mzupdt
  {
    path: '',
    redirectTo: 'load',
    pathMatch: 'full'
  },

  { path: 'load', loadChildren: () => import('./load/load.module').then(m => m.LoadPageModule) },
  // mzupdt { path: '', loadChildren: () => import('./tabs/tabs.module').then(m => m.TabsPageModule) },
  { path: '', loadChildren: () => import('./tabs/tabs.module').then(m => m.TabsPageModule), canActivate: [AuthGuard] },
  { path: 'login', loadChildren: () => import('./login/login.module').then(m => m.LoginPageModule) },
  { path: 'signup', loadChildren: () => import('./signup/signup.module').then(m => m.SignupPageModule) },
  { path: 'create-team', loadChildren: () => import('./create-team/create-team.module').then(m => m.CreateTeamPageModule), canActivate: [AuthGuard] },
  { path: 'accountready', loadChildren: () => import('./accountready/accountready.module').then(m => m.AccountreadyPageModule), canActivate: [AuthGuard] },
  { path: 'selectartist', loadChildren: () => import('./selectartist/selectartist.module').then(m => m.SelectartistPageModule), canActivate: [AuthGuard] },
  { path: 'accountrecovery', loadChildren: () => import('./accountrecovery/accountrecovery.module').then(m => m.AccountrecoveryPageModule) },
  { path: 'accountnonconfirmed', loadChildren: () => import('./accountnonconfirmed/accountnonconfirmed.module').then(m => m.AccountnonconfirmedPageModule) },
  { path: 'selectmusic', loadChildren: () => import('./selectmusic/selectmusic.module').then(m => m.SelectmusicPageModule) },
  { path: 'findingmusic', loadChildren: () => import('./findingmusic/findingmusic.module').then(m => m.FindingmusicPageModule) },
  { path: 'premium', loadChildren: () => import('./premium/premium.module').then(m => m.PremiumPageModule) },
  { path: 'settings', loadChildren: () => import('./settings/settings.module').then(m => m.SettingsPageModule) },
  { path: 'songslist', loadChildren: () => import('./songslist/songslist.module').then(m => m.SongslistPageModule) },
  { path: 'musicplayer', loadChildren: () => import('./musicplayer/musicplayer.module').then(m => m.MusicplayerPageModule) },
  { path: 'addplaylist', loadChildren: () => import('./addplaylist/addplaylist.module').then(m => m.AddplaylistPageModule) },
  { path: 'library', loadChildren: () => import('./library/library.module').then(m => m.LibraryPageModule) },
  { path: 'addsongs', loadChildren: () => import('./addsongs/addsongs.module').then(m => m.AddsongsPageModule) },
  { path: 'mightlike', loadChildren: () => import('./mightlike/mightlike.module').then(m => m.MightlikePageModule) },
  { path: 'genretype', loadChildren: () => import('./genretype/genretype.module').then(m => m.GenretypePageModule) },
  { path: 'podcast', loadChildren: () => import('./podcast/podcast.module').then(m => m.PodcastPageModule) },
  { path: 'podcastcategories', loadChildren: () => import('./podcastcategories/podcastcategories.module').then(m => m.PodcastcategoriesPageModule) },
  { path: 'languages', loadChildren: () => import('./languages/languages.module').then(m => m.LanguagesPageModule) },
  { path: 'notification', loadChildren: () => import('./notification/notification.module').then(m => m.NotificationPageModule) },

];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
