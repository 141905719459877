import { Injectable } from '@angular/core';
import { ToastController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class AlertService {

  constructor(private toastController: ToastController) { }

  async presentToast(message: any, duration: number = 2000) {
    const toast = await this.toastController.create({
      // message: message,
      message,
      //duration: 2000,
      duration,
      position: 'top',
      color: 'dark'
    });
    toast.present();
  }
}
